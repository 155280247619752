@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Kufi+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap");

html, body, #root, #direction-wrap {
  width: 100%;
  height: 100%;
}

.flex-center, .flex-column-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column-center {
  flex-direction: column;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(129, 129, 129);
  border: 2px solid #555555;
}

.PrivatePickersToolbar-root .MuiButtonBase-root.PrivateDatePickerToolbar-penIcon {
  display: none;
}
